export const ContactUscountryList = [
    {
        "id": "10520",
        "country": "Afghanistan"
    },
    {
        "id": "10521",
        "country": "Albania"
    },
    {
        "id": "10522",
        "country": "Algeria"
    },
    {
        "id": "10523",
        "country": "Andorra"
    },
    {
        "id": "10524",
        "country": "Angola"
    },
    {
        "id": "10525",
        "country": "Antigua and Barbuda"
    },
    {
        "id": "10526",
        "country": "Argentina"
    },
    {
        "id": "10527",
        "country": "Armenia"
    },
    {
        "id": "10528",
        "country": "Australia"
    },
    {
        "id": "10529",
        "country": "Austria"
    },
    {
        "id": "10530",
        "country": "Azerbaijan"
    },
    {
        "id": "10531",
        "country": "Bahamas"
    },
    {
        "id": "10532",
        "country": "Bahrain"
    },
    {
        "id": "10533",
        "country": "Bangladesh"
    },
    {
        "id": "10534",
        "country": "Barbados"
    },
    {
        "id": "10535",
        "country": "Belarus"
    },
    {
        "id": "10536",
        "country": "Belgium"
    },
    {
        "id": "10537",
        "country": "Belize"
    },
    {
        "id": "10538",
        "country": "Benin"
    },
    {
        "id": "10539",
        "country": "Bhutan"
    },
    {
        "id": "10540",
        "country": "Bolivia"
    },
    {
        "id": "10541",
        "country": "Bosnia and Herzegovina"
    },
    {
        "id": "10542",
        "country": "Botswana"
    },
    {
        "id": "10543",
        "country": "Brazil"
    },
    {
        "id": "10544",
        "country": "Brunei"
    },
    {
        "id": "10545",
        "country": "Bulgaria"
    },
    {
        "id": "10546",
        "country": "Burkina Faso"
    },
    {
        "id": "10547",
        "country": "Burundi"
    },
    {
        "id": "10548",
        "country": "Côte d'Ivoire"
    },
    {
        "id": "10549",
        "country": "Cabo Verde"
    },
    {
        "id": "10550",
        "country": "Cambodia"
    },
    {
        "id": "10551",
        "country": "Cameroon"
    },
    {
        "id": "10552",
        "country": "Canada"
    },
    {
        "id": "10553",
        "country": "Central African Republic"
    },
    {
        "id": "10554",
        "country": "Chad"
    },
    {
        "id": "10555",
        "country": "Chile"
    },
    {
        "id": "10556",
        "country": "China"
    },
    {
        "id": "10557",
        "country": "Colombia"
    },
    {
        "id": "10558",
        "country": "Comoros"
    },
    {
        "id": "10559",
        "country": "Congo (Congo-Brazzaville)"
    },
    {
        "id": "10560",
        "country": "Costa Rica"
    },
    {
        "id": "10561",
        "country": "Croatia"
    },
    {
        "id": "10562",
        "country": "Cuba"
    },
    {
        "id": "10563",
        "country": "Cyprus"
    },
    {
        "id": "10564",
        "country": "Czechia (Czech Republic)"
    },
    {
        "id": "10565",
        "country": "Democratic Republic of the Congo"
    },
    {
        "id": "10566",
        "country": "Denmark"
    },
    {
        "id": "10567",
        "country": "Djibouti"
    },
    {
        "id": "10568",
        "country": "Dominican Republic"
    },
    {
        "id": "10569",
        "country": "Ecuador"
    },
    {
        "id": "10570",
        "country": "Egypt"
    },
    {
        "id": "10571",
        "country": "El Salvador"
    },
    {
        "id": "10572",
        "country": "Equatorial Guinea"
    },
    {
        "id": "10573",
        "country": "Eritrea"
    },
    {
        "id": "10574",
        "country": "Estonia"
    },
    {
        "id": "10575",
        "country": "Eswatini (fmr. \"Swaziland\")"
    },
    {
        "id": "10576",
        "country": "Ethiopia"
    },
    {
        "id": "10577",
        "country": "Fiji"
    },
    {
        "id": "10578",
        "country": "Finland"
    },
    {
        "id": "10579",
        "country": "France"
    },
    {
        "id": "10580",
        "country": "Gabon"
    },
    {
        "id": "10581",
        "country": "Gambia"
    },
    {
        "id": "10582",
        "country": "Georgia"
    },
    {
        "id": "10583",
        "country": "Germany"
    },
    {
        "id": "10584",
        "country": "Ghana"
    },
    {
        "id": "10585",
        "country": "Greece"
    },
    {
        "id": "10586",
        "country": "Grenada"
    },
    {
        "id": "10587",
        "country": "Guatemala"
    },
    {
        "id": "10588",
        "country": "Guinea"
    },
    {
        "id": "10589",
        "country": "Guinea-Bissau"
    },
    {
        "id": "10590",
        "country": "Guyana"
    },
    {
        "id": "10591",
        "country": "Haiti"
    },
    {
        "id": "10592",
        "country": "Holy See"
    },
    {
        "id": "10593",
        "country": "Honduras"
    },
    {
        "id": "10594",
        "country": "Hungary"
    },
    {
        "id": "10595",
        "country": "Iceland"
    },
    {
        "id": "10596",
        "country": "India"
    },
    {
        "id": "10597",
        "country": "Indonesia"
    },
    // {
    //     "id": "10598",
    //     "country": "Iran"
    // },
    {
        "id": "10599",
        "country": "Iraq"
    },
    {
        "id": "10600",
        "country": "Ireland"
    },
    {
        "id": "10601",
        "country": "Israel"
    },
    {
        "id": "10602",
        "country": "Italy"
    },
    {
        "id": "10603",
        "country": "Jamaica"
    },
    {
        "id": "10604",
        "country": "Japan"
    },
    {
        "id": "10605",
        "country": "Jordan"
    },
    {
        "id": "10606",
        "country": "Kazakhstan"
    },
    {
        "id": "10607",
        "country": "Kenya"
    },
    {
        "id": "10608",
        "country": "Kiribati"
    },
    {
        "id": "10609",
        "country": "Kuwait"
    },
    {
        "id": "10610",
        "country": "Kyrgyzstan"
    },
    {
        "id": "10611",
        "country": "Laos"
    },
    {
        "id": "10612",
        "country": "Latvia"
    },
    {
        "id": "10613",
        "country": "Lebanon"
    },
    {
        "id": "10614",
        "country": "Lesotho"
    },
    {
        "id": "10615",
        "country": "Liberia"
    },
    {
        "id": "10616",
        "country": "Libya"
    },
    {
        "id": "10617",
        "country": "Liechtenstein"
    },
    {
        "id": "10618",
        "country": "Lithuania"
    },
    {
        "id": "10619",
        "country": "Luxembourg"
    },
    {
        "id": "10620",
        "country": "Madagascar"
    },
    {
        "id": "10621",
        "country": "Malawi"
    },
    {
        "id": "10622",
        "country": "Malaysia"
    },
    {
        "id": "10623",
        "country": "Maldives"
    },
    {
        "id": "10624",
        "country": "Mali"
    },
    {
        "id": "10625",
        "country": "Malta"
    },
    {
        "id": "10626",
        "country": "Marshall Islands"
    },
    {
        "id": "10627",
        "country": "Mauritania"
    },
    {
        "id": "10628",
        "country": "Mauritius"
    },
    {
        "id": "10629",
        "country": "Mexico"
    },
    {
        "id": "10630",
        "country": "Micronesia"
    },
    {
        "id": "10631",
        "country": "Moldova"
    },
    {
        "id": "10632",
        "country": "Monaco"
    },
    {
        "id": "10633",
        "country": "Mongolia"
    },
    {
        "id": "10634",
        "country": "Montenegro"
    },
    {
        "id": "10635",
        "country": "Morocco"
    },
    {
        "id": "10636",
        "country": "Mozambique"
    },
    {
        "id": "10637",
        "country": "Myanmar (formerly Burma)"
    },
    {
        "id": "10638",
        "country": "Namibia"
    },
    {
        "id": "10639",
        "country": "Nauru"
    },
    {
        "id": "10640",
        "country": "Nepal"
    },
    {
        "id": "10641",
        "country": "Netherlands"
    },
    {
        "id": "10642",
        "country": "New Zealand"
    },
    {
        "id": "10643",
        "country": "Nicaragua"
    },
    {
        "id": "10644",
        "country": "Niger"
    },
    {
        "id": "10645",
        "country": "Nigeria"
    },
    // {
    //     "id": "10646",
    //     "country": "North Korea"
    // },
    {
        "id": "10647",
        "country": "North Macedonia"
    },
    {
        "id": "10648",
        "country": "Norway"
    },
    {
        "id": "10649",
        "country": "Oman"
    },
    {
        "id": "10650",
        "country": "Pakistan"
    },
    {
        "id": "10651",
        "country": "Palau"
    },
    {
        "id": "10652",
        "country": "Palestine State"
    },
    {
        "id": "10653",
        "country": "Panama"
    },
    {
        "id": "10654",
        "country": "Papua New Guinea"
    },
    {
        "id": "10655",
        "country": "Paraguay"
    },
    {
        "id": "10656",
        "country": "Peru"
    },
    {
        "id": "10657",
        "country": "Philippines"
    },
    {
        "id": "10658",
        "country": "Poland"
    },
    {
        "id": "10659",
        "country": "Portugal"
    },
    {
        "id": "10660",
        "country": "Qatar"
    },
    {
        "id": "10661",
        "country": "Romania"
    },
    // {
    //     "id": "10662",
    //     "country": "Russia"
    // },
    {
        "id": "10663",
        "country": "Rwanda"
    },
    {
        "id": "10664",
        "country": "Saint Kitts and Nevis"
    },
    {
        "id": "10665",
        "country": "Saint Lucia"
    },
    {
        "id": "10666",
        "country": "Saint Vincent and the Grenadines"
    },
    {
        "id": "10667",
        "country": "Samoa"
    },
    {
        "id": "10668",
        "country": "San Marino"
    },
    {
        "id": "10669",
        "country": "Sao Tome and Principe"
    },
    {
        "id": "10670",
        "country": "Saudi Arabia"
    },
    {
        "id": "10671",
        "country": "Senegal"
    },
    {
        "id": "10672",
        "country": "Serbia"
    },
    {
        "id": "10673",
        "country": "Seychelles"
    },
    {
        "id": "10674",
        "country": "Sierra Leone"
    },
    {
        "id": "10675",
        "country": "Singapore"
    },
    {
        "id": "10676",
        "country": "Slovakia"
    },
    {
        "id": "10677",
        "country": "Slovenia"
    },
    {
        "id": "10678",
        "country": "Solomon Islands"
    },
    {
        "id": "10679",
        "country": "Somalia"
    },
    {
        "id": "10680",
        "country": "South Africa"
    },
    {
        "id": "10681",
        "country": "South Korea"
    },
    {
        "id": "10682",
        "country": "South Sudan"
    },
    {
        "id": "10683",
        "country": "Spain"
    },
    {
        "id": "10684",
        "country": "Sri Lanka"
    },
    {
        "id": "10685",
        "country": "Sudan"
    },
    {
        "id": "10686",
        "country": "Suriname"
    },
    {
        "id": "10687",
        "country": "Sweden"
    },
    {
        "id": "10688",
        "country": "Switzerland"
    },
    {
        "id": "10689",
        "country": "Syria"
    },
    {
        "id": "10690",
        "country": "Tajikistan"
    },
    {
        "id": "10691",
        "country": "Tanzania"
    },
    {
        "id": "10692",
        "country": "Thailand"
    },
    {
        "id": "10693",
        "country": "Timor-Leste"
    },
    {
        "id": "10694",
        "country": "Togo"
    },
    {
        "id": "10695",
        "country": "Tonga"
    },
    {
        "id": "10696",
        "country": "Trinidad and Tobago"
    },
    {
        "id": "10697",
        "country": "Tunisia"
    },
    {
        "id": "10698",
        "country": "Turkey"
    },
    {
        "id": "10699",
        "country": "Turkmenistan"
    },
    {
        "id": "10700",
        "country": "Tuvalu"
    },
    {
        "id": "10701",
        "country": "Uganda"
    },
    {
        "id": "10702",
        "country": "Ukraine"
    },
    {
        "id": "10703",
        "country": "United Arab Emirates"
    },
    {
        "id": "10704",
        "country": "UK"
    },
    {
        "id": "10705",
        "country": "USA"
    },
    {
        "id": "10706",
        "country": "Uruguay"
    },
    {
        "id": "10707",
        "country": "Uzbekistan"
    },
    {
        "id": "10708",
        "country": "Vanuatu"
    },
    // {
    //     "id": "10709",
    //     "country": "Venezuela"
    // },
    {
        "id": "10710",
        "country": "Vietnam"
    },
    {
        "id": "10711",
        "country": "Yemen"
    },
    {
        "id": "10712",
        "country": "Zambia"
    },
    {
        "id": "10713",
        "country": "Zimbabwe"
    }
]
