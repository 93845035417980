import { Box, Typography } from '@mui/material';
import React from 'react'
import CredentialCountBox from './CredentialCountBox';
import { ReactComponent as Card } from './Image/Card.svg';
import { ReactComponent as Pin } from './Image/Pin.svg';
import { ReactComponent as PalmVein } from './Image/PalmVein.svg';
import { ReactComponent as FingerVein } from './Image/FingerVein.svg';
import { ReactComponent as FingerPrint } from './Image/fingerprint.svg';
import { ReactComponent as Facial } from './Image/Facial.svg';
import { useSnackbar } from 'notistack';
import { remoteRegistration } from '../../../services/DeviceService';
import { useState } from 'react';
import SimpleDailogBox from '../../../components/SimpleDailogBox';
import BioSpecForHandAndPalm from './BioSpecForHandAndPalm';
import BioSpecForPin from './BioSpecForPin';
import { useTranslation } from 'react-i18next';

const CredentialCount = (props) => {
  const { face, palm, fingerPrint, fingerVein } = props.deviceSupports;
  const { enqueueSnackbar } = useSnackbar();
  const [openDialog, setOpenDialog] = useState(false);
  const [needFinger, setNeedFinger] = useState(false);
  const [openPwdDialog, setOpenPwdDialog]=useState(false);
  const [pin, setPin] = useState(null);
  const {t}=useTranslation();

  const getSupport = (param) => {
    var temp = props.enrollSupport.split(':');
    if (temp[param] == '1') {
      return true;
    }
    return false;
  }

  const cardSupport = () =>{
    return (props.cardEnrollSupport && props.cardEnrollSupport == '1') ? true : false;
  }

  const checkDeviceSpecs = (data) => {
    let device=[
      props.deviceDetails.ip,
      props.credential.firstName,
      props.credential.lastName];

    if(props.deviceDetails.status===1){
    if (face === data.face) {
      let payload = {
        enrollType: "9",
        pin: data.pin,
        fid: 0
      }
      remoteRegistration(props.devId,device, payload, (data) => {
        if (data?.data?.code === 'DMSI0000') {
          enqueueSnackbar(data.data.msg, {
            variant: 'success',
          });
        } else {
          enqueueSnackbar(data.data.msg, {
            variant: 'error',
          });
        }
      })
    } else if (palm === data.palm) {
      setPin({data:data,deviceDetails:props.deviceDetails})
      setNeedFinger(false);
      setOpenDialog(!openDialog);

    } else if (fingerPrint === data.fingerPrint) {
      setPin({data:data,deviceDetails:props.deviceDetails})
      setNeedFinger(true)
      setOpenDialog(!openDialog);

    } else if (fingerVein === data.fingerVein) {
      let payload = {
        enrollType: "7",
        pin: data.pin,
        fid: 0
      }
      remoteRegistration(props.devId,device, payload, (data) => {
        if (data?.data?.code === 'DMSI0000') {
          enqueueSnackbar(data.data.msg, {
            variant: 'success',
          });
        } else {
          enqueueSnackbar(data.data.msg, {
            variant: 'error',
          });
        }
      });
    }
    else if(data?.passcode==1){
      setPin({data:data,deviceDetails:props.deviceDetails})
      setOpenPwdDialog(!openPwdDialog);
    } else if(data?.card == 1){
      let payload = {
        enrollType: "card",
        pin: data.pin
      }

      remoteRegistration(props.devId,device, payload, (data) => {
        if (data?.data?.code === 'DMSI0000') {
          enqueueSnackbar(data.data.msg, {
            variant: 'success',
          });
        } else {
          enqueueSnackbar(data.data.msg, {
            variant: 'error',
          });
        }
      });
    }
    else {
      enqueueSnackbar(t('AccDevice054'), {
        variant: 'error',
    });
    }
  }
   else {
      enqueueSnackbar(t("AccDevice065"), {
        variant: 'error',
      });
    }
  }
  
  const handleClose=()=>{
    setOpenDialog(false);
    setOpenPwdDialog(false);
  }

  return (
    <>
      <Box display={'flex'} width={'100%'} heigth={'100%'}>
        <Box display={'flex'} width={'50%'} marginRight={"2%"}>
          <CredentialCountBox
          // deleteIndividualCredential={props.deleteIndividualCredential}
            icon={<Pin
              style={{ cursor: 'pointer' }}
              onClick={() =>checkDeviceSpecs({ passcode: '1', pin: props.credential.employeeCode })}/>}
            value={props.credential.credentialCount.passcode !== 0 ? props.credential.credentialCount.passcode : 0}
            data={props.credential}
            credentialType={credentialType.passcode}
          />
        </Box>
        {cardSupport() && <Box display={'flex'} width={'50%'} marginRight={"2%"}>
          <CredentialCountBox
          //  deleteIndividualCredential={props.deleteIndividualCredential}
            icon={<Card
              style={{ cursor: 'pointer' }}
              onClick={() => checkDeviceSpecs({ card: '1', pin: props.credential.employeeCode })} />}
            value={props.credential.credentialCount.card !== 0 ? props.credential.credentialCount.card : 0}
            data={props.credential}
            credentialType={credentialType.card}
          />
        </Box>}
        {getSupport(1) &&
          <Box display={'flex'} width={'50%'} marginRight={"2%"}>
            <CredentialCountBox
              // deleteIndividualCredential={props.deleteIndividualCredential}
              icon={<FingerPrint
                style={{ cursor: 'pointer' }}
                onClick={() => checkDeviceSpecs({ fingerPrint: '1', pin: props.credential.employeeCode })} />}
              value={props.credential.credentialCount.fingerPrint !== 0 ? props.credential.credentialCount.fingerPrint : 0}
              data={props.credential}
              credentialType={credentialType.fingerPrint}
            />
          </Box>
        }

        {getSupport(9) &&
          <Box display={'flex'} width={'50%'} marginRight={"2%"}>
            <CredentialCountBox
              // deleteIndividualCredential={props.deleteIndividualCredential}
              icon={<Facial
                style={{ cursor: 'pointer' }}
                onClick={() => checkDeviceSpecs({ face: '1', pin: props.credential.employeeCode })} />}
              value={props.credential.credentialCount.visibleLightFace !== 0 ? props.credential.credentialCount.visibleLightFace : 0}
              data={props.credential}
              credentialType={credentialType.visibleLightFace}
            />
          </Box>
        }

        {getSupport(7) &&
          <Box display={'flex'} width={'50%'} marginRight={"2%"}>
            <CredentialCountBox
              // deleteIndividualCredential={props.deleteIndividualCredential}
              icon={<FingerVein
                style={{ cursor: 'pointer' }}
                onClick={() => checkDeviceSpecs({ fingerVein: '1', pin: props.credential.employeeCode })} />}
              value={props.credential.credentialCount.fingerVein !== 0 ? props.credential.credentialCount.fingerVein : 0}
              data={props.credential}
              credentialType={credentialType.fingerVein}
            />
          </Box>
        }
        
        {getSupport(8) &&
          <Box display={'flex'} width={'50%'}>
            <CredentialCountBox
              //  deleteIndividualCredential={props.deleteIndividualCredential}
              icon={<PalmVein
                style={{ cursor: 'pointer' }}
                onClick={() => checkDeviceSpecs({ palm: '1', pin: props.credential.employeeCode })} />}
              value={props.credential.credentialCount.palm !== 0 ? props.credential.credentialCount.palm : 0}
              data={props.credential}
              credentialType={credentialType.palm}
            />
          </Box>
        }
      </Box>

       <SimpleDailogBox open={openDialog} onClose={handleClose} width={'400px'} title={'Set Biometric Details'}>
        <BioSpecForHandAndPalm  pin={pin} devId={props.devId} needFinger={needFinger} onClose={handleClose} credential={props.credential}/>
      </SimpleDailogBox>

      <SimpleDailogBox open={openPwdDialog} onClose={handleClose}  width={'400px'} title={'Set Biometric Details'}>
        <BioSpecForPin pin={pin} reload={()=>props.reload()} credential={props.credential}devId={props.devId} onClose={handleClose}/>
      </SimpleDailogBox>
    </>
  )
}

export default CredentialCount

export const credentialType = {
  passcode: '102',
  card: '100',
  fingerPrint: '1',
  visibleLightFace: '9',
  fingerVein: '7',
  palm: '8'
};
